import React from "react";
import { Button, Img, Heading } from "../../components";
import { Link, useLocation } from "react-router-dom";
import { NumericFormat } from "react-number-format";

const Institutions = ({ institutions }) => {
	const locations = useLocation();
	const getLocations = locations.pathname.split('/')[1];
	const truncateText = (text, maxWords) => {
		const words = text.split(' ');
		if (words.length > maxWords) {
			return words.slice(0, maxWords).join(' ') + '...';
		}
		return text;
	};
	return (
		<div className=" max-w-[940px] w-full md:w-[940px] py-10 ">
			<div className="justify-center w-full gap-y-10 gap-x-5 grid-cols-1 lg:grid-cols-3 grid px-10">
				{
					institutions.map((item, index) => (
						<div key={ index } className="flex flex-col justify-between w-full h-full bg-white-A700" >
							<Link to={ getLocations === 'institutions' ? `/charities/${ item.id }` : `/foundations/${ item.id }` } >
								<div>
									<div className="flex flex-row justify-center w-full border-gray-400 border-b-[0.5px] border-solid">
										<Img
											src={ item.image_url }
											alt={ getLocations === 'institutions' ? item.charity_name : item.name }
											className="w-full  h-[150px] object-contain"
										/>
									</div>
									<div className="flex flex-col p-3 gap-2.5">
										<Heading as="p" className="mt-1 !text-xl">
											{ getLocations === 'institutions' ? item.charity_name : item.name }
										</Heading>
										{ getLocations === "institutions" && (
											<div className="w-full h-[6px] bg-gray-200 rounded">
												<div
													className="h-full bg-blue rounded"
													style={ { width: `${ item.progress * 100 }%` } }
												/>
											</div>
										) }
										{ getLocations === 'institutions' &&
											<Heading size="sm" as="p" className="!text-base">
												<NumericFormat value={ `${ item.nominal_current_achievement || '0' }` } displayType={ 'text' } thousandSeparator={ true } prefix={ '$' } /> - <NumericFormat value={ `${ item.nominal_target || '0' }` } displayType={ 'text' } thousandSeparator={ true } prefix={ '$' } />
											</Heading> }
										{ getLocations === 'foundations' &&
											<Heading as="p" className="!text-sm !font-normal !text-blue_gray-700" dangerouslySetInnerHTML={ { __html: truncateText(item.description, 20) } } /> }
									</div>
								</div>
							</Link>
							<Link to={ getLocations === 'institutions' ? `/charities/${ item.id }` : `/institutions/${ item.id }` } className="flex flex-col items-center my-3">
								<Button size="sm" className="h-[40px] mb-1 uppercase font-medium w-[140px] !text-base">
									{ getLocations === 'institutions' ? 'Donate' : 'View More' }
								</Button>
							</Link>
						</div>
					))
				}
			</div>
		</div>
	);
};

export default Institutions;